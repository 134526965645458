import React, { useState, useEffect } from "react";
import { RESOURCE_KEYS, orgPermissions } from "utils/OrgPermissions";
import { logError } from "utils/error";
import { useListController, useRefresh } from "react-admin";
import { addTags, hasPermissionToEditLogs } from "webpage-leads/webpageHelpers";
import ExlyImage from "common/Components/ExlyImage";
import SendMessage from "@material-ui/icons/Send";
import { initiateBulkAction, performBulkAction } from "../BulkActions.utils";
import {
  BULK_ACTION_TABLES,
  BULK_ACTION_TYPES,
  INPUT_TYPE,
  requestIdParam,
} from "../../BulkActions.constants";
import { isRequestSuccessful } from "utils/Utils";
import constants from "constants/constants";
import { notification_color_keys, useNotifications } from "utils/hooks";
import WhastappLogo from "assets/vectors/whatsapp_green.svg";
import EmailLogo from "assets/vectors/email_arrow.svg";
import { DRIP_MODE } from "ui/pages/EmailDrip/EmailDrip.constants";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import cssStyles from "../../BulkActionsStyles.module.css";
import { ENTITY_TYPE } from "constants/crmConstants";
import BulkActionButton from "features/BulkActions/modules/BulkActionButton/BulkActionButton";
import { AFFLIATE_COMMISSION_STATUS } from "features/AffiliateMarketing/constants/Affliates.constants";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import { useAllTransactions } from "features/AffiliateMarketing/modules/AffliateTransactions/modules/AllTransactions/utils/useAllTransactions";
import { COMISSION_PAYOUT_TYPE_IDS } from "features/AffiliateMarketing/modules/AffliateDetail/constants/detail.constants";
import {
  AFFILIATE_MARKETING_ROUTE_IDS,
  AFFILIATE_MARKETING_URL_KEYS,
} from "features/AffiliateMarketing/constants/Affliates.urlPaths";
import {
  AFFILIATE_BULK_ACTION_KEYS,
  AFFLIATE_STATUS,
} from "features/AffiliateMarketing/constants/AffiliateMarketing.constants";
import usePayout from "features/AffiliateMarketing/modules/AffliateCommissionStats/modules/PayoutActions/utils/usePayout";
import { is_empty } from "features/Common/utils/common.utils";
import { getCrmAsset } from "features/Crm/utils/Crm.utils";

const useBulkActions = (props) => {
  const { initiateTransactionsReview } = useAllTransactions();

  const [selectedfilteredIds, setSelectedFilteredIds] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState({});
  const [showBroadcastModal, setShowBroadcastModal] = useState(false);
  const [showAddTagsModal, setShowAddTagsModal] = useState(false);
  const handlePayout = usePayout();

  const [currentPageFullSelection, setCurrentPageFullSelection] =
    useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [emailId, setEmailId] = useState(null);

  const { onSelect, data, ids, page, filterValues } = useListController(props);
  const refetch = useRefresh();

  const { notify } = useNotifications();
  const canSendEmail = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
  );
  const canSendWhatsapp = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS
  );

  const canBroadcastMessage = canSendEmail || canSendWhatsapp;
  const getTableActions = (feature) => {
    const affliatesTransactions =
      feature === RESOURCE_KEYS.SUBSECTIONS.AFFILIATE.AFFLIATE_TRANSACTIONS;
    const isBookingFeature =
      feature === RESOURCE_KEYS.SUBSECTIONS.MY_BOOKINGS.BOOKINGS;
    const isNewAffiliate =
      feature === RESOURCE_KEYS.SUBSECTIONS.AFFILIATE.NEW_AFFLIATES;
    const data = [];
    if (isNewAffiliate) {
      data.push(
        {
          hide: !hasPermissionToEditLogs(feature),
          onClick: () => {
            initiateApproval(
              selectedRecords,
              BULK_ACTION_TABLES.AFFLIATES,
              AFFLIATE_STATUS.approved.value,
              AFFILIATE_BULK_ACTION_KEYS.affiliate_status
            );
          },
          text: "Approve",
          icon: <CheckCircleOutlineIcon className={cssStyles.checkIcon} />,
          customClass: {
            label: cssStyles.acceptBtn,
            wrapper: cssStyles.acceptWrapper,
          },
        },
        {
          hide: !hasPermissionToEditLogs(feature),
          onClick: () => {
            initiateApproval(
              selectedRecords,
              BULK_ACTION_TABLES.AFFLIATES,
              AFFLIATE_STATUS.rejected.value,
              AFFILIATE_BULK_ACTION_KEYS.affiliate_status
            );
          },
          text: "Reject",
          icon: <CancelIcon className={cssStyles.crossIcon} />,
          customClass: {
            label: cssStyles.rejectBtn,
            wrapper: cssStyles.rejectWrapper,
          },
        }
      );
      return data;
    }
    if (affliatesTransactions) {
      data.push({
        hide: !hasPermissionToEditLogs(feature),
        text: "Bulk Actions",
        options: [
          {
            label: "Mark selected as approved ",
            value: 6,
            onClick: () =>
              initiateApproval(
                selectedRecords,
                BULK_ACTION_TABLES.AFFLIATES_ALL_TRANSACTIONS,
                6,
                AFFILIATE_BULK_ACTION_KEYS.commission_action_type,
                BULK_ACTION_TYPES.COMMISSSION_STATUS
              ),
          },
          {
            label: "Mark selected as rejected",
            value: 7,
            onClick: () =>
              initiateApproval(
                selectedRecords,
                BULK_ACTION_TABLES.AFFLIATES_ALL_TRANSACTIONS,
                7,
                AFFILIATE_BULK_ACTION_KEYS.commission_action_type,
                BULK_ACTION_TYPES.COMMISSSION_STATUS
              ),
          },
          {
            label: "Payout only pending and approved from selection",
            value: 3,
            onClick: () => {
              const urlPath = `${window.location.origin}/#/${
                AFFILIATE_MARKETING_ROUTE_IDS[
                  AFFILIATE_MARKETING_URL_KEYS.list_all_transcation
                ]
              }`;
              const records = Object.values(selectedRecords).map(
                (record) => record.uuid
              );
              if (window.location.href === urlPath) {
                const payload = {
                  commission_payout_type:
                    COMISSION_PAYOUT_TYPE_IDS.approved_and_pending,
                };
                if (!is_empty(records)) {
                  payload.transaction_uuids = records;
                }
                initiateTransactionsReview({
                  payload,
                });
              } else {
                const payload = {
                  payoutType: COMISSION_PAYOUT_TYPE_IDS.approved_and_pending,
                };
                if (!is_empty(records)) {
                  payload.transaction_uuids = records;
                }
                handlePayout(payload);
              }
            },
          },
          {
            label: "Payout commission for all select transactions",
            value: 4,
            onClick: () => {
              const urlPath = `${window.location.origin}/#/${
                AFFILIATE_MARKETING_ROUTE_IDS[
                  AFFILIATE_MARKETING_URL_KEYS.list_all_transcation
                ]
              }`;
              const records = Object.values(selectedRecords).map(
                (record) => record.uuid
              );
              if (window.location.href === urlPath) {
                const payload = {
                  commission_payout_type: COMISSION_PAYOUT_TYPE_IDS.all,
                };
                if (!is_empty(records)) {
                  payload.transaction_uuids = records;
                }
                // if all affiliate transactions then to open review transactions modal
                initiateTransactionsReview({
                  payload,
                });
              } else {
                const payload = {
                  payoutType: COMISSION_PAYOUT_TYPE_IDS.all,
                };
                if (!is_empty(records)) {
                  payload.transaction_uuids = records;
                }
                // if single affiliate transactions then open payout modal
                handlePayout(payload);
              }
            },
          },
        ],
        type: INPUT_TYPE.dropdown,
      });
      return data;
    }
    if (!isBookingFeature) {
      data.push({
        hide: !hasPermissionToEditLogs(feature),
        onClick: () => {
          setShowAddTagsModal(true);
          setEmailId(null); // need to set the emailId as null before initiating assign tags via bulk action
        },
        text: "Assign Tag",
        icon: (
          <ExlyImage
            src={getCrmAsset("add_tag_white.svg")}
            alt="Bulk Tag"
            height={20}
            width={20}
            id="tag"
          />
        ),
      });
    }
    data.push({
      hide: !canBroadcastMessage,
      onClick: () => setShowBroadcastModal(true),
      text: "Send message",
      icon: <SendMessage alt="Send Message" className={cssStyles.iconStyle} />,
    });

    return data;
  };

  const getFilteredRowsFromSelection = (rows) => {
    let selectedRows = { ...selectedRecords };

    // Incase the row was selected earlier, but now it is disselected, we need to remove it
    // If a record is present on current page, is in our selected records state, but not present
    // in the rows array, then it is disselected
    Object.keys(selectedRows).forEach((key) => {
      if (data[key] && !rows.includes(key)) {
        delete selectedRows[key];
      }
    });

    rows.forEach((rowIndex) => {
      selectedRows[rowIndex] = data[rowIndex];
    });
    return selectedRows;
  };

  const handleRowsSelected = (rows) => {
    let rowsToSend = rows;
    if (selectAll) {
      setSelectAll(false);
      const keys = Object.keys(data)?.map((item) => {
        const num = +item;
        return !isNaN(num) ? num : item; // need to convert item into number only if +item wont be NaN;
      });
      rowsToSend = keys?.filter?.((item) => !rows.includes(item));
    }
    manageSelecton(rowsToSend);
  };

  const manageSelecton = (rows) => {
    if (!rows.length && Object.keys(selectedRecords)?.length === 1) {
      onSelect([]);
      setSelectedRecords({});
      setSelectedFilteredIds([]);
      return;
    }
    const selectedRows = getFilteredRowsFromSelection(rows);
    // on first click (header checkbox) we are setting the selectedfilteredIds by filtered values
    // on second click (header checkbox) we are deselecting all the rows
    // and set selectedfilteredIds and selectedIds empty

    const selectedIds = rows.filter((rowId) => selectedRows?.[rowId]);

    const selectedRowsIds = Object.keys(selectedRows);
    if (
      selectedRowsIds.length === selectedfilteredIds.length &&
      rows.length === ids.length
    ) {
      onSelect([]);
      setSelectedRecords({});
      setSelectedFilteredIds([]);
      return;
    }
    setSelectedRecords({ ...selectedRows });
    setSelectedFilteredIds(selectedRowsIds);
    onSelect(selectedIds);
    handleCurrentPageFullSelection({ selectedRecords: selectedRows });
  };
  const handleCurrentPageFullSelection = ({ selectedRecords }) => {
    const selectedRecordKeys = Object.keys(selectedRecords);
    const dataKeys = Object.keys(data);
    const allCurrentPageEntriesSelected =
      selectedRecordKeys.length === dataKeys.length &&
      selectedRecordKeys.every((key) => dataKeys.includes(key));
    setCurrentPageFullSelection(allCurrentPageEntriesSelected);
  };
  const onTagsAssign = async ({
    values,
    hideTagsModal,
    selectedRecords,
    tableName,
    skipExistingCheck = false,
    entityName = ENTITY_TYPE.customer.key,
  }) => {
    const noResultsInSelectAll = is_empty(data) && selectAll;
    if ((is_empty(selectedRecords) && !selectAll) || noResultsInSelectAll) {
      notify(
        `Please select ${entityName}s first`,
        notification_color_keys.error
      );
      return;
    }

    if (!tableName) return;
    try {
      let tagToAssign;

      if (!is_empty(values.tag_name)) {
        const data = await addTags(values.tag_name, skipExistingCheck);
        tagToAssign = [data?.uuid];
      } else {
        tagToAssign = values.select_tag;
      }

      if (is_empty(tagToAssign)) {
        notify(
          `Please select or create tag first`,
          notification_color_keys.error
        );
        return;
      }

      const actionData = { tag_uuid: tagToAssign };

      const { data, status } = await initiateBulkAction({
        bulkActionTable: tableName,
        actionType: BULK_ACTION_TYPES.ASSIGN_TAGS,
        selectedIds: Object.keys(selectedRecords),
        actionData,
        filterData: filterValues,
        selectAll,
      });

      if (isRequestSuccessful(status)) {
        const requestId = data?.request_id;
        if (!requestId) return;

        const { status: performBulkActionStatus, message } =
          await performBulkAction({
            requestId,
          });

        if (isRequestSuccessful(performBulkActionStatus)) {
          onSelect([]);
          setSelectedRecords({});
          setSelectAll(false);
          refetch();
          notify(message, notification_color_keys.success);
        } else {
          notify(constants.error_message, notification_color_keys.error);
          return;
        }
      }

      hideTagsModal && hideTagsModal();

      return { tagToAssign, status };
    } catch (err) {
      logError({
        err,
        occuredAt:
          "onTagsAssign in src/features/BulkActions/utils/hooks/useBulkAction.js",
        when: "calling assignTags",
      });
      notify(
        err?.message || "Error while setting tags",
        notification_color_keys.error
      );
    }
  };

  const initiateBroadcast = async ({
    tableName,
    broadcastType,
    entityName = ENTITY_TYPE.customer.key,
    listingUUID,
  }) => {
    let filterData = filterValues;
    const noResultsInSelectAll = is_empty(data) && selectAll;
    if ((is_empty(selectedRecords) && !selectAll) || noResultsInSelectAll) {
      notify(
        `Please select ${entityName}s first`,
        notification_color_keys.error
      );
      return;
    }

    if (!tableName) return;

    // if listing uuid param exist , need to append it to filterData (currently happens when bulk actions are performed from manage bookings)
    if (listingUUID) {
      filterData = {
        ...filterData,
        listing_uuid: listingUUID,
      };
    }

    try {
      const { data, status } = await initiateBulkAction({
        bulkActionTable: tableName,
        actionType: BULK_ACTION_TYPES.BROADCAST,
        selectedIds: Object.keys(selectedRecords),
        selectAll,
        filterData,
      });

      if (isRequestSuccessful(status)) {
        const requestId = data?.request_id;
        if (!requestId) return;
        const baseUrl =
          broadcastType === DRIP_MODE.EMAIL
            ? `/${app_route_ids[[app_route_keys.compose_email]]}`
            : app_route_ids[
                [app_route_keys.manage_communications_whatsapp_select]
              ];
        window.location.href = `${window.location.origin}/#${baseUrl}?${requestIdParam}=${requestId}`;
      }
    } catch (err) {
      notify(
        err?.message || "Error while initiating broadcast action",
        notification_color_keys.error
      );
      logError({
        err,
        occuredAt:
          "initiateBroadcast in src/features/BulkActions/utils/hooks/useBulkAction.js",
        when: "calling initiateBroadcast",
      });
    }
  };

  const getBroadcastActions = ({
    action,
    tableName,
    entityName = ENTITY_TYPE.customer.key,
  }) => [
    {
      title: "Send WhatsApp Broadcast",
      description: `Broadcast will be sent to all the selected ${entityName}s`,
      onClick: () => action({ tableName, broadcastType: DRIP_MODE.WHATSAPP }),
      hidden: !orgPermissions.hasFeatureWriteAccess(
        RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS
      ),
      img: WhastappLogo,
    },
    {
      title: "Send an Email",
      description: `Send a Custom Email to the selected ${entityName}s`,
      onClick: () => action({ tableName, broadcastType: DRIP_MODE.EMAIL }),
      hidden: !orgPermissions.hasFeatureWriteAccess(
        RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
      ),
      img: EmailLogo,
    },
  ];

  const handleSelectAll = (value) => {
    setSelectAll(value);
    setSelectedRecords({});
    onSelect([]);
  };

  useEffect(() => {
    if (!is_empty(data) && !is_empty(selectedRecords)) {
      const selectedRecordsValue = Object.keys(data).filter((dataIndex) =>
        Object.keys(selectedRecords)?.includes(dataIndex)
      );
      const finalSelectedIdsValue = selectedRecordsValue?.map((item) => {
        const num = +item;
        return !isNaN(num) ? num : item; // need to convert item into number only if +item wont be NaN;
      });

      const singleSelectedValue = Object.keys(selectedRecords).find(
        (selectedIndex) => !data[selectedIndex]
      );
      const selectedIds = !is_empty(finalSelectedIdsValue)
        ? finalSelectedIdsValue
        : [singleSelectedValue];

      onSelect(selectedIds);
      handleCurrentPageFullSelection({ selectedRecords: selectedRecords });
    }
  }, [page, data]);

  useEffect(() => {
    if (!is_empty(filterValues)) {
      handleRowsSelected([]);
    }
  }, [filterValues]);

  /**
   * Generates footer props for bulk actions and primary footer button.
   *
   * @param {Array} bulkActions - The list of bulk actions.
   * @param {Object} primaryFooterProps - The properties for the primary footer button.
   * @returns {Object} - The footer props including primary and secondary button JSX.
   */
  const generateFooterProps = ({ bulkActions, primaryFooterProps }) => {
    const showPrimaryFooterBtn =
      is_empty(selectedRecords) && !selectAll && primaryFooterProps;
    const [_firstBulkAction, _secondBulkAction] = bulkActions;
    let footerProps = {};

    if (_secondBulkAction)
      footerProps = {
        ...footerProps,
        primaryBtnJSX: <BulkActionButton actionItem={_secondBulkAction} />,
        hidePrimarybtn: _secondBulkAction.hide,
      };

    if (_firstBulkAction)
      footerProps = {
        ...footerProps,
        secondaryBtnJSX: (
          <BulkActionButton
            actionItem={_firstBulkAction}
            isFullLayout={!_secondBulkAction}
          />
        ),
        hideSecondaryBtn: _firstBulkAction.hide,
      };

    if (showPrimaryFooterBtn && !primaryFooterProps.hide)
      footerProps = {
        primaryBtnJSX: (
          <BulkActionButton actionItem={primaryFooterProps} isFullLayout />
        ),
        hidePrimarybtn: primaryFooterProps.hide,
      };

    return footerProps;
  };
  const initiateApproval = async (
    selectedRecords,
    tableName,
    affliateStatus,
    key,
    actionType = BULK_ACTION_TYPES.STATUS
  ) => {
    try {
      const actionData = { [key]: affliateStatus };
      const { data, status } = await initiateBulkAction({
        bulkActionTable: tableName,
        actionType: actionType,
        selectedIds: Object.keys(selectedRecords),
        actionData,
        selectAll,
      });

      if (isRequestSuccessful(status)) {
        const requestId = data?.request_id;
        if (!requestId) return;

        const { status: performBulkActionStatus, message } =
          await performBulkAction({
            requestId,
          });

        if (isRequestSuccessful(performBulkActionStatus)) {
          onSelect([]);
          setSelectedRecords({});
          setSelectAll(false);
          refetch();
          if (
            affliateStatus === AFFLIATE_STATUS.approved.value ||
            affliateStatus === AFFLIATE_COMMISSION_STATUS[1].value
          ) {
            notify(message, notification_color_keys.success);
          } else {
            notify(message, notification_color_keys.error);
          }
        } else {
          notify(constants.error_message, notification_color_keys.error);
          return;
        }
      }

      return { status };
    } catch (err) {
      logError({
        err,
        occuredAt:
          "onInitiateApproval in src/features/BulkActions/utils/hooks/useBulkAction.js",
        when: "calling assignTags",
      });
      notify(
        err?.message || "Error while setting tags",
        notification_color_keys.error
      );
    }
  };

  const handleClearSelection = () => {
    onSelect([]);
    setSelectedRecords({});
    setSelectAll(false);
  };

  return {
    getTableActions,
    showAddTagsModal,
    setShowAddTagsModal,
    showBroadcastModal,
    setShowBroadcastModal,
    handleRowsSelected,
    selectedRecords,
    setSelectedRecords,
    onTagsAssign,
    initiateBroadcast,
    currentPageFullSelection,
    selectAll,
    setSelectAll,
    getBroadcastActions,
    handleSelectAll,
    generateFooterProps,
    emailId,
    setEmailId,
    handleClearSelection,
  };
};

export default useBulkActions;
